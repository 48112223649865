<template>
  <div class="my-projects">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "MyProjects"
};
</script>
